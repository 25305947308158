

















import Vue from 'vue';
import AvatarProfileDialog from '@/components/accounts/AccountProfileDialog.vue';

import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'ProfilePage',
  components: {
    AvatarProfileDialog
  },

  computed: {
    ...mapGetters(['getThemeMode']),
    ...mapGetters({ user: 'auth/getUser' })
  }
});
