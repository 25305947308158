


































































































































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ErrorManager, UserFieldError } from '@/models';

export default Vue.extend({
  props: {
    size: { type: Number, default: 90 }
  },

  data: () => ({
    dragover: false,
    imageUploaded: false,
    imageLoading: false,
    dialog: false,
    loading: false,
    cachedImage: null as null | string | ArrayBuffer | File,

    errorMessage: '',
    fieldError: new UserFieldError()
  }),

  computed: {
    ...mapGetters({ user: 'auth/getUser' }),
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    computedDisplayedImage(): string {
      return this.user.profile && this.user.profile.avatar;
    }
  },

  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser'
    }),
    close() {
      this.dialog = false;
      this.cachedImage = null;
      this.imageUploaded = false;
    },

    clearAllErrors(): void {
      this.fieldError.clearErrors();
      this.errorMessage = '';
    },

    inputClick() {
      const fileImage = this.$refs.fileImage as HTMLInputElement;
      fileImage.click();
    },

    async onChange(e: DragEvent) {
      const files =
        e.dataTransfer?.files ?? (e.target as HTMLInputElement)?.files;

      if (files?.length) {
        this.cachedImage = files[0];
      }

      this.saveUpdateUserAvatar();
    },

    onDrop(e: DragEvent) {
      this.dragover = false;
      this.onChange(e);
    },

    async saveUpdateUserAvatar() {
      this.clearAllErrors();

      this.loading = true;
      await this.updateUser({
        profile: {
          avatar: this.cachedImage
        }
      })
        .then(() => {
          this.imageUploaded = true;
        })
        .catch((error) => {
          this.cachedImage = null;

          if (error.response) {
            // client received an error response that falls out of range 2xx
            if (UserFieldError.isUserFieldError(error.response.data)) {
              this.fieldError = new UserFieldError(error.response.data);
            }
          }

          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
